import axios from 'axios';

const APIFailedConnect = (orderID, body) => {
    try {
        return axios.post(`/api/orders/${orderID}/refusal`, body).then(() => 'done');
    } catch (e) {
        return e.response.data;
    }
}

export const FailedConnectTender = async (orderID, comment) => {
    const response = await APIFailedConnect(orderID, {comment});

    return response;
}
