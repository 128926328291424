<template>
  <CModal
    color='danger'
    title="Провайдер не виконав підключення"
    :show.sync="showFailedConnect"
    size="lg"
    addContentClasses="srl"
  >
    <template #body-wrapper>
      <div class="px-4 py-2">
        <ul v-if="error.length >= 1" style="list-style: none" class="p-0">
          <li v-for="err of error" :key="error.indexOf(err)">
            <CAlert style="font-size: 12px;" class="text-left" show :color="err.color">{{ err.text }}</CAlert>
          </li>
        </ul>
        <div class="form-item" :class="{'errorInput': $v.remark.$error}">
          <label class="text-left">Зауваження на провайдера</label>
          <CTextarea
            required
            rows="7"
            placeholder="Напишіть своє зауваження"
            :maxlength="$v.remark.$params.maxLength.max +1 "
            @change="$v.remark.$touch()"
            :class=" {'error': $v.remark.$error}"
            v-model="remark"
          ></CTextarea>
          <small v-if="!$v.remark.maxLength" class="form-text errorText w-100">Максимальна
            кількість символів
            {{ $v.remark.$params.maxLength.max }}!</small>
          <small v-if="!$v.remark.minLength || !$v.remark.required" class="form-text errorText w-100">Мінімальна
            кількість символів
            {{ $v.remark.$params.minLength.min }}, поле є обов'язковим!</small>
        </div>
      </div>
    </template>
    <template #footer>
      <CButton
        color="secondary"
        @click="removeRemark"
      >
        Відмінити
      </CButton>
      <CButton
        color="danger"
        @click="changeConnection()"
      >
        Скасувати переможця
      </CButton>
    </template>
  </CModal>
</template>

<script>
import {FailedConnectTender} from "../../../api/failedConnectiTender";
import {required, minLength, maxLength} from 'vuelidate/lib/validators'

export default {
  name: "BlockUnblockProvider",
  props: {
    showFailedConnect: {
      type: Boolean,
      require: true,
      default: false
    },
    order_id: {
      type: String,
      require: true,
      default: null
    }
  },
  validations: {
    remark: {
      minLength: minLength(1),
      maxLength: maxLength(500),
      required: true
    }
  },
  data: () => {
    return {
      remark: '',
      error: [],
    }
  },
  beforeDestroy() {
    this.clearDate();
  },
  watch: {
    showFailedConnect(val) {
      this.$v.$reset();
      if (!val) return this.removeRemark();
    }
  },
  methods: {
    async changeConnection() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let req = await FailedConnectTender(this.$props.order_id, this.remark);
        if (req === 'done') this.doneSend();
        else this.errorSend(req);
      }

    },
    errorSend(err) {
      this.error.push({
        status: 'bad',
        color: 'danger',
        text: err || 'Під час виконання запиту сталась помилка, спробуйте пізніше.'
      })

      setTimeout(() => {
        this.removeRemark();
      }, 1000);
    },
    doneSend() {
      this.error.push({
        status: 'done',
        color: 'success',
        text: 'Зауваження відправлене, можете вибрати іншого переможця.'
      });

      setTimeout(() => {
        // this.$store.dispatch('GET_PROVIDER');

        this.removeRemark();
        this.$emit('update');
      }, 1000);

    },
    removeRemark() {
      this.$emit('close', false);
      this.clearDate();
    },
    clearDate() {
      this.error = [];
      this.remark = '';
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item .errorText {
  display: none;
  //font-size: 1rem !important;
  color: #cb203d;
}

.form-item {
  &.errorInput .errorText {
    display: block;
  }
}

.error {
  border-color: #ff002a !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-radius: .3rem;
}
</style>
