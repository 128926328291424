<template>
  <div>
    <CButton
      color="danger"
      v-bind="$attrs"
      @click="failedConnect(order_id)"
    >
      Не виконав підключення
    </CButton>
    <modal-failed-to-connect-tender
      :showFailedConnect="showModal"
      :order_id="order_id"
      @close="showModalFailedConnect(false)"
      @update="$emit('update')"
    />
  </div>
</template>
<script lang="js">
import ModalFailedToConnectTender from "./Modal/ModalFailedToConnectTender";

export default {
  props: {
    order_id: {
      required: true,
      default: '',
      type: String
    }
  },
  components: {ModalFailedToConnectTender},
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    showModalFailedConnect(show) {
      this.showModal = show;
    },
    failedConnect(order_id) {
      this.showModalFailedConnect(true)
    }
  }
}
</script>
