<template>
  <CRow class="w-100 justify-content-center">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol col="12" sm="8" md="8" lg="8">
            <div class="card-header-action mt-2 ml-2">
              <h2>Прикріплення документів</h2>
              <CAlert v-if="message.show" style="font-size: 12px;" show color="danger">{{ message.failed_message }}
              </CAlert>
              <CAlert v-if="message.show_not_upload" style="font-size: 12px;" show color="danger">
                {{ message.failed_not_upload_message }}
              </CAlert>
              <CAlert v-if="message.show_not_upload_any_error" style="font-size: 12px;" show color="danger">
                {{ message.failed_not_upload_any_error_message }}
              </CAlert>
            </div>
          </CCol>
          <CCol col="12" sm="4" md="4" lg=4 class="text-right">
            <failed-to-connect v-if="btnNotCompletedConnection()" :order_id="order.id" @update="funcShowDate"/>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody class="justify-content-between">
        <CRow class="m-3">
          <CCol class="p-5 border-right col-12 col-lg-4">
            <div class="row border-bottom mb-2">
              <p class="col-12 font-weight-bold">ID тендеру
                <btn-copy :value="orderId"/>
              </p>
              <p class="col-12">
                <router-link :to="`/tenders/${order.id}`" class="">{{ order.id }}</router-link>
              </p>
            </div>
            <div class="row border-bottom mb-2" v-if="can('bank_user')">
              <p class="col-12 font-weight-bold">Причина тендера</p>
              <p class="col-12">{{ order.reason_type }}</p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 font-weight-bold">Призначення</p>
              <p class="col-12">{{ order.channel_purpose }}</p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 font-weight-bold">Тип каналу</p>
              <p class="col-12">{{ order.connect_type }}</p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 font-weight-bold">Бранч А</p>
              <p class="col-12">{{ order.branch_a }}</p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 font-weight-bold">Адреса точки А</p>
              <p class="col-12 ">{{ order.branch_a_address }}</p>
            </div>
            <div class="row border-bottom mb-2" v-if="order.connect_type !== 'Інтернет'">
              <p class="col-12 font-weight-bold">Бранч Б</p>
              <p class="col-12">{{ order.branch_b }}</p>
            </div>
            <div class="row border-bottom mb-2" v-if="order.connect_type !== 'Інтернет'">
              <p class="col-12 font-weight-bold">Адреса точки Б</p>
              <p class="col-12">{{ order.branch_b_address }}</p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 font-weight-bold">Вид каналу</p>
              <p class="col-12">{{ order.consumer_type }}</p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 font-weight-bold">Швидкість, Мбіт/с</p>
              <p class="col-12">{{ order.channel_speed }}</p>
            </div>
            <div v-if="can('bank_user')" class="row border-bottom mb-2">
              <p class="col-12 font-weight-bold">Додаткове</p>
              <p class="col-12">{{ order.extra }}</p>
            </div>

          </CCol>
          <CCol class="p-5 border-right col-12 col-lg-4">
                      <span v-if="can('bank_user')">
                        <CCardHeader class="mb-2 pb-0 pl-0">
                          <h4>Провайдер info</h4>
                        </CCardHeader>
                        <div class="row border-bottom mb-2">
                          <p class="col-12 font-weight-bold">Ім'я Організації</p>
                          <p class="col-12">{{ offers.name }}</p>
                        </div>
                        <div class="row border-bottom mb-2">
                          <p class="col-12 font-weight-bold">Бренд</p>
                          <p class="col-12">{{ offers.brand }}</p>
                        </div>
                        <div class="row border-bottom mb-2" v-if="offers.ipn">
                          <p class="col-12 font-weight-bold">ІПН</p>
                          <p class="col-12">{{ offers.ipn }}</p>
                        </div>
                        <div class="row border-bottom mb-2" v-else>
                          <p class="col-12 font-weight-bold">ЕРДПОУ</p>
                          <p class="col-12">{{ offers.edr }}</p>
                        </div>
                          <p><a @click="moreInfoProvider()"
                                class="border-bottom text-primary">{{
                              !moreInfo ? 'Показати контакти...' : 'Сховати'
                            }}</a></p>
                          <span v-if="moreInfo">
                               <div class="row border-bottom mb-2">
                                  <p class="col-12 font-weight-bold">Web-site</p>
                                  <p class="col-12">{{ offers.website }}</p>
                                </div>
                                <div class="row border-bottom mb-2">
                                  <p class="col-12 font-weight-bold">Телефон абон.відділу/бухгалтерії</p>
                                  <p class="col-12">
        <!--                              {{offers.accounting_contacts}}-->
                                         <ul style="list-style-type: none" class="p-0">
                                          <li v-for="tel in offers.accounting_contacts" :key="tel">
                                            <a :href="'tel://'+tel">
                                              {{ tel }}
                                            </a>
                                          </li>
                                        </ul>
                                  </p>
                                </div>

                                <div class="row border-bottom mb-2">
                                  <p class="col-12 font-weight-bold">Email абон.відділу/бухгалтерії</p>
                                  <p class="col-12">
        <!--                              {{offers.accounting_emails}}-->
                                      <ul style="list-style-type: none" class="p-0">
                                          <li v-for="mail in offers.accounting_emails" :key="mail">
                                            <a :href="'mailto:' + mail">
                                              {{ mail }}
                                            </a>
                                          </li>
                                      </ul>
                                  </p>
                                </div>
                                  <div class="row border-bottom mb-2">
                                  <p class="col-12 font-weight-bold">Телефон Тех.підтримки</p>
                                  <p class="col-12">
        <!--                              {{offers.technical_support_contacts}}-->
                                    <ul style="list-style-type: none" class="p-0">
                                      <li v-for="tel in offers.technical_support_contacts" :key="tel">
                                        <a :href="'tel://'+tel">
                                          {{ tel }}
                                        </a>
                                      </li>
                                    </ul>
                                    </p>
                                </div>
                                <div class="row border-bottom mb-2">
                                  <p class="col-12 font-weight-bold">Email Тех.підтримки</p>
                                  <p class="col-12">
        <!--                              {{offers.technical_emails}}-->
                                      <ul style="list-style-type: none" class="p-0">
                                          <li v-for="mail in offers.technical_emails" :key="mail">
                                            <a :href="'mailto:' + mail">
                                              {{ mail }}
                                            </a>
                                          </li>
                                      </ul>
                                  </p>
                                </div>
                              <div class="row border-bottom mb-2">
                                <CCardHeader class="border-bottom-0 pl-0 pt-lg-3">
                                    <h4>Користувачі</h4>
                                </CCardHeader>
                                <CCardBody
                                    v-if="offers.provider_users !== 0"
                                    class="p-0 m-0">
                                    <CDataTable
                                        hover
                                        striped
                                        :items="offers.provider_users"
                                        :fields="fieldsUsers"
                                        :items-per-page="20"
                                        :pagination="$options.paginationProps"
                                        index-column
                                        clickable-rows
                                    >
                                        <template #email="data">
                                            <td>
                                                {{ data.item.email }}
                                            </td>
                                        </template>
                                        <template #name="data">
                                            <td>
                                                {{ data.item.first_name }} {{ data.item.last_name }}
                                            </td>
                                        </template>
                                        <template #phones="data">
                                          <td>
                                                <ul style="list-style-type: none; width: 180px" class="p-0">
                                                  <li v-for="tel in data.item.phones.split(',')" :key="tel">
                                                      <a :href="'tel://'+tel">
                                                          {{ tel }}
                                                      </a>
                                                  </li>
                                                </ul>
                                          </td>
                                        </template>
                                    </CDataTable>
                                </CCardBody>
                              </div>

                          </span>
                      </span>

            <span v-if="can('provider_user')">
                        <CRow class="justify-content-center">
                            <label for="file"
                                   :class="[is_file ? 'chousAnotherFile':'chous']">{{
                                !is_file ? massage.noFile : massage.another
                              }}</label>
                        </CRow>
                        <CAlert v-if="is_file" style="font-size: 12px;" show
                                color="success">{{ massage.isFile }}</CAlert>

              <!--                    <CAlert v-if="message.show" style="font-size: 12px;"  show color="danger">{{message.failed_message_images_upload}}</CAlert>-->
                        <input class="d-none" type="file" id="file" value="4000" ref="file" multiple
                               @change="handleFileUpload" accept=".doc, .docx">
                        <CRow class="justify-content-center">
<!--                        <CButton v-if="is_file" class="p-2 mb-1" color="danger"  @click.prevent="removeFile">Видалити</CButton>-->
                            <span v-if="is_file && name_file !== null" class="w-100 ">
                                <ul style="list-style-type: none" class="p-0">
                                    <li class="border-bottom mb-3" v-for="item in name_file" :key="item">
                                        <p>
                                            <CRow>
                                                <CCol col="6" class="p-0 m-0">
                                                    {{ item }}
                                                </CCol>
                                                <CCol col="6" class="p-0 m-0 text-right">
                                                    <a target="_blank"
                                                       :href="`${baseURL}/api/account/offers/${offer_id}/file/${encodeURIComponent(`${item}`)}`">
                                                        <CButton v-if="is_file" class="p-1 mb-1 mr-1"
                                                                 style="font-size: 11px;"
                                                                 color="success">Переглянути</CButton>
                                                    </a>
                                                    <CButton v-if="is_file" class="p-1 mb-1" color="danger"
                                                             style="font-size: 11px;" @click.prevent="removeFile(item)">Видалити</CButton>
                                                </CCol>
                                            </CRow>
                                        </p>
                                    </li>
                                </ul>
                            </span>
                        </CRow>
                      </span>
            <span v-if="can('bank_user')">
                            <CAlert v-if="is_file" style="font-size: 12px;" show
                                    color="success">{{ massage.isFile }}</CAlert>
                            <CAlert v-else style="font-size: 12px;" show color="info">{{ massage.notFound }}</CAlert>

              <!--                        <label v-if="is_file" class="noFoundFile">{{massage.isFile}}</label>-->
              <!--                        <label v-else class="noFoundFile">{{massage.notFound}}</label>-->

              <!--                    <CAlert v-if="message.show" style="font-size: 12px;"  show color="danger">{{message.failed_message_images_upload}}</CAlert>-->
              <!--                            <CRow class="justify-content-center">-->
              <!--                              <a target="_blank" :href="`${baseURL}/api/offers/${offer_id}/file`"><CButton v-if="is_file" class="p-2 px-4 mb-1" color="success">Переглянути</CButton></a>-->
              <!--                            </CRow>`-->
                            <span v-if="is_file && name_file !== null">
                                <ul style="list-style-type: none" class="justify-content-between d-block">
                                    <li v-for="item in name_file" :key="item">
                                         <p>
                                            <CRow>
                                                <CCol col="6" class="p-0 m-0">
                                                    {{ item }}
                                                </CCol>
                                                <CCol col="6" class="p-0 m-0 text-right">
                                                    <a target="_blank"
                                                       :href="`${baseURL}/api/offers/${offer_id}/file/${encodeURIComponent(`${item}`)}`">
                                                        <CButton v-if="is_file" class="p-1 mb-1 mr-1"
                                                                 style="font-size: 11px;"
                                                                 color="success">Скачати</CButton>
                                                    </a>
                                                </CCol>
                                            </CRow>
                                        </p>
                                    </li>
                                </ul>
                            </span>
                        </span>
          </CCol>
          <CCol class="p-5  col-12 col-lg-4">
            <table class="table">
              <tbody>
              <tr>
                <th>Вартість підключення, грн з ПДВ</th>
                <td>{{ offers.connection_cost }}</td>
              </tr>
              <tr>
                <th>Щомісячний платіж, грн з ПДВ</th>
                <td>{{ offers.monthly_payment }}</td>
              </tr>
              <tr>
                <th>Швидкість на прийом, Мбіт/с <br>(Європа)</th>
                <td>{{ offers.download_speed }}</td>
              </tr>
              <tr>
                <th>Швидкість на віддачу, Мбіт/с <br>(Європа)</th>
                <td>{{ offers.upload_speed }}</td>
              </tr>
              <tr>
                <th>Тип каналу</th>
                <td>{{ offers.type_channel }}</td>
              </tr>
              <tr>
                <th>Фізика</th>
                <td>{{ $store.getters.getPhysicsByID(offers.physics_id) }}</td>
              </tr>
              <tr>
                <th>Інтерфейс</th>
                <td>{{ $store.getters.getInterfaceByID(offers.iface_id) }}</td>
              </tr>
              <tr>
                <th>Протокол</th>
                <td>{{ $store.getters.getProtocolByID(offers.protocol_id) }}</td>
              </tr>
              <tr>
                <th>Потрібно обладнання?</th>
                <td>{{ offers.is_equipment ? "Так" : "Ні" }}</td>
              </tr>
              <tr v-if="offers.is_equipment">
                <th>Впишіть обладнання</th>
                <td>{{ offers.extra }}</td>
              </tr>
              <tr v-if="offers.is_equipment">
                <th>Чиє обладнання?</th>
                <td>{{ offers.whose_equipment }}</td>
              </tr>
              <tr v-if="offers.is_equipment">
                <th>Умови передачі обладнання?</th>
                <td>{{ offers.equipment_transfer }}</td>
              </tr>
              <tr>
                <th> Необхідний час для організації каналу</th>
                <td>{{ offers.days_to_connect }}</td>
              </tr>
              <tr>
                <th>Примітка</th>
                <td>{{ offers.note }}</td>
              </tr>
              </tbody>
            </table>
            <div v-if="can('bank_user')" class="row border-bottom mb-2">
              <p class="col-12 font-weight-bold">Історія по заявці</p>
              <p class="col-12">
                <user-info-with-data
                    :action="order_history?.action"
                    :name="order_history?.name"
                    :login="order_history?.login"
                    :date="order_history?.date"
                    :extra="order_history?.extra"
                />
              </p>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CRow>
</template>

<script>
import axios from 'axios'
import failedToConnect from "../Components/failedToConnect";
import BtnCopy from "../Components/Button/BtnCopy.vue";
import UserInfoWithData from "../Components/UserInfoWithData.vue";
import {getOrderHistory} from "@/api/getOrderHistory";
import {can} from "@/rules/permissions";

export default {
  name: "AttachDocument",
  components: {BtnCopy, failedToConnect, UserInfoWithData},
  data: () => {
    return {
      order_history: [],
      fieldsUsers: [
        {key: 'email', label: 'Емейл'},
        {key: 'name', label: "Ім'я"},
        {key: 'phones', label: 'Контактні телефони'},
      ],
      moreInfo: false,
      baseURL: '',
      is_file: false,
      name_file: null,
      offer_id: '',
      is_admin: false,
      massage: {
        noFile: "Прикріпити договір: doc, docx.",
        another: "Прикріпити ще один документ",
        isFile: "Документ прикріплено",
        isDelete: "Видалити договір",
        notFound: "Договір не додано"
      },
      offers: {},

      order: {},
      providers: {},
      physics: {},
      interfaces: [],
      protocol: [],
      message: {
        show: false,
        failed_message: "Не видалено, спробуйте пізніще",
        show_not_upload: false,
        failed_not_upload_message: "Ви загрузили не вірний формат файл. Дозволені формати тільки doc, docx",
        show_not_upload_any_error: false,
        failed_not_upload_any_error_message: "Ви загрузили не вірний формат файл",
      }
    }
  },
  methods: {
    can,
    async getHistory(order_id = undefined) {
      if (!order_id) return;
      const order_history = await getOrderHistory(order_id);
      // this.order_history = order_history.pop();
      this.order_history = order_history.reverse().find(obj => obj.action === 'Визначено переможця тендеру');
    },
    btnNotCompletedConnection() {
      return can('attach_doc_failed_connect') && this.order.status === 'CloseWithResult'
    },

    moreInfoProvider() {
      this.moreInfo = !this.moreInfo;
    },

    uploadFile() {
      let self = this;
      let is_upload_file = `/api/offers/${self.offer_id}/file`;
      let formData = new FormData();
      formData.append(`file`, this.file);
      // You should have a server side REST API
      axios.post(is_upload_file,
          formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function () {
        self.message.show_not_upload = false;
        self.message.show_not_upload_any_error = false;
        self.getOffersFile();
      }).catch(function (error) {
        if (error.response.data.message === 'BadFormatFile') {
          self.message.show_not_upload = true;
          return;
        }
        self.message.show_not_upload_any_error = true;
      });
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      //console.log('>>>> 1st element in files array >>>> ', this.file);
      this.uploadFile();
    },

    removeFile(name_file) {
      let self = this;
      let is_remove_file = `/api/offers/${self.offer_id}/file/${encodeURIComponent(`${name_file}`)}`;

      axios.delete(is_remove_file).then(function () {
        self.getOffersFile();
      }).catch(function () {
      })
    },

    getOffersById() {
      this.offer_id = this.$route.params.offer_id
      let self = this;
      let Is_offersById = '';
      if (this.is_admin) {
        Is_offersById = `/api/offers/${self.offer_id}`
      }
      if (!this.is_admin) {
        Is_offersById = `/api/account/offers/${self.offer_id}`
      }
      axios.get(Is_offersById)
          .then(function (response) {
            self.offers = response.data;
            if (self.is_admin) {
              const newVersionUrl = `${self.baseURL}/v2/tenders/${self.offers.order_id}/offers/${self.offer_id}/doc`;
              window.location.href = newVersionUrl;
              // self.$router.push(`/v2/tenders/${self.offers.order_id}/offers/${self.offer_id}/doc`);
              return;

              // self.offers.accounting_contacts = self.offers.accounting_contacts.split(',');
              // self.offers.accounting_emails = self.offers.accounting_emails.split(',');
              // self.offers.technical_support_contacts = self.offers.technical_support_contacts.split(',');
              // self.offers.technical_emails = self.offers.technical_emails.split(',');
            }
            self.getOrderById(self.offers.order_id);
          }).catch(function () {
      });
    },

    getOrderById(id) {
      let self = this;
      let Is_OrderBuId = '';
      if (this.is_admin) {
        Is_OrderBuId = `/api/orders/${id}`
      }
      if (!this.is_admin) {
        Is_OrderBuId = `/api/account/orders/${id}`
      }
      axios.get(Is_OrderBuId).then(function (response) {
        self.order = response.data
        self.getHistory(self.order?.id);
      }).catch(function () {
      });
    },

    getOffersFile() {
      let self = this;
      let show_offers_file = '';
      if (this.is_admin) {
        show_offers_file = `/api/offers/${self.offer_id}/file`
      }
      if (!this.is_admin) {
        show_offers_file = `/api/account/offers/${self.offer_id}/file`
      }
      axios.get(show_offers_file)
          .then(function (response) {
            self.is_file = false;
            if (response.status === 204 || response.status === 200 && response.data) {
              self.is_file = true;
              self.name_file = response.data;
            }
          })
          .catch(function (error) {
            if (error.response.data.message === 'OrderOffersFileNotFound') {
              self.is_file = false;
            }
          })
    },

    funcShowDate() {
      if (can('bank_user') && can('attach_doc_work_with_rules')) this.is_admin = true;
      if (can('provider_user')) this.is_admin = false;

      this.getOffersById();
      this.getOffersFile();
    },

  },
  computed: {
    orderId() {
      return this.order && this.order.id ? this.order?.id.toString() ?? '' : '';
    }
  },
  created() {
    this.$store.dispatch('GET_ORGANIZATION');
    if (!this.$store.getters.getPhysicsLength) this.$store.dispatch('GET_Physics');
    if (!this.$store.getters.getInterfaceLength) this.$store.dispatch('GET_Interface');
    if (!this.$store.getters.getProtocolLength) this.$store.dispatch('GET_Protocol');

    this.funcShowDate();
    this.baseURL = axios.defaults.baseURL;
  },
  watch: {
    is_file: function (val) {
      console.log(val)
    },
    '$store.state.login_info.ready': function () {
      return this.funcShowDate();
    },
  },
}
</script>
